console.log('top');

var video = document.getElementById('mov');
var video2 = document.getElementById('mov_sp');

var setAnimation = lottie.loadAnimation({
  container: document.getElementById('tivel'),
  renderer: 'svg',
  loop: false,
  autoplay: true,
  path: 'assets/img/top/loading.json'
});

var setAnimation = lottie.loadAnimation({
  container: document.getElementById('tivel_sp'),
  renderer: 'svg',
  loop: false,
  autoplay: true,
  path: 'assets/img/top/loading_sp.json'
});


// // アニメーション終了時に実行
setAnimation.addEventListener("complete", function () {
  const loadingElement = document.querySelector(".loading");
  loadingElement.classList.add("isLoaded");{
    $('header').addClass('display_b');
    video.play();
    video2.play();
  }
});

// // 初回訪問時のみアニメーションを実行
// if (!sessionStorage.getItem("visited")) {
// 	// 初回訪問時
// 	sessionStorage.setItem("visited", "first");
//   const setAnimation = lottie.loadAnimation({
//   container: document.querySelector(".loading-wrapper"), // アニメーションをさせたいdivなどhtml要素ににつけた名前
//   });
// } else {
// 	// 2回目以降の訪問時
// 	const loadingElement = document.querySelector(".loading");
//   loadingElement.classList.add("isLoaded");{
//     $('header').addClass('display_b');
//     video.play();
//     video2.play();
//   }
// }
