// if (window.matchMedia('(max-width: 768px)').matches) {
//     let type_img_scroll = document.querySelector(".type_img");
//     type_img_scroll.scrollLeft = 230;
// }



window.addEventListener('load', function () {
  var elements = document.getElementsByClassName('type_img');

  // 取得した要素に対して処理を行う
  for (var i = 0; i < elements.length; i++) {
    if (window.matchMedia('(max-width: 768px)').matches) {
      let type_img_scroll = elements[i];
      type_img_scroll.scrollLeft = 230; // 230px にスクロール
    }
  }
});

$(".tab_basic").on('click', function(){
  $(".tab_basic,.type_img_basic,.type_st_wrap_basic,.type_img_p_basic").addClass("active");
  $(".tab_menu,.type_img_menu,.type_st_wrap_premiere").removeClass("active");

  // 100ms 後にスクロール位置を調整
  setTimeout(function() {
    if (window.matchMedia('(max-width: 768px)').matches) {
      let type_img_scroll = document.querySelector(".type_img_basic");
      type_img_scroll.scrollLeft = 230; // 230px にスクロール
    }
  }, 10); // 10ms の遅延
});

$(".tab_menu").on('click', function(){
  $(".tab_menu,.type_img_menu,.type_st_wrap_premiere").addClass("active");
  $(".tab_basic,.type_img_basic,.type_st_wrap_basic,.type_img_p_basic").removeClass("active");

  // 100ms 後にスクロール位置を調整
  setTimeout(function() {
    if (window.matchMedia('(max-width: 768px)').matches) {
      let type_img_scroll = document.querySelector(".type_img_menu");
      type_img_scroll.scrollLeft = 230; // 230px にスクロール
    }
  }, 10); // 10ms の遅延
});
