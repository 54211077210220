

let concept_ul = document.querySelectorAll(".sec1a li");
let concept_clss = document.querySelectorAll("#pop_movie .close");

let concept_ul_index;
concept_ul.forEach((concept_li) => {
    concept_li.addEventListener("click", () => {
        var concept_ul_index = [].slice.call(concept_ul).indexOf(concept_li);


        // document.body.classList.remove('pp1','pp2','pp3','pp4','pp5','pp6','pp7','pp8');
        // document.body.classList.add('pp'+(concept_ul_index+1),'popupen');
    });
});


// concept_clss.forEach((concept_cls) => {
//     concept_cls.addEventListener("click", () => {
//         document.body.classList.remove('pp1','pp2','pp3','pp4','pp5','pp6','pp7','pp8','popupen');
//         // $('#pop_movie').scrollTop(0);
//     });
// });

if (window.matchMedia('(max-width: 768px)').matches) {


}

