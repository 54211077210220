

let commn_space_ul = document.querySelectorAll(".sec2c li");
let commn_space_clss = document.querySelectorAll("#pop_movie .close");
let commn_space_i_05s = document.querySelectorAll(".i_05 .ino");

let commn_space_ul_index;
commn_space_ul.forEach((commn_space_li) => {
    commn_space_li.addEventListener("click", () => {
        var commn_space_ul_index = [].slice.call(commn_space_ul).indexOf(commn_space_li);

        document.body.classList.remove('pp1','pp2','pp3','pp4','pp5','pp6','pp7','pp8');
        document.body.classList.add('pp'+(commn_space_ul_index+1),'popupen');
    });
});
let commn_space_i05_index;
commn_space_i_05s.forEach((commn_space_i05_li) => {
    commn_space_i05_li.addEventListener("click", () => {
        commn_space_i05_index =  commn_space_i05_li.getAttribute( "rel");

        document.body.classList.remove('pp1','pp2','pp3','pp4','pp5','pp6','pp7','pp8');
        document.body.classList.add('pp'+(commn_space_i05_index),'popupen');
    });
});

commn_space_clss.forEach((commn_space_cls) => {
    commn_space_cls.addEventListener("click", () => {
        document.body.classList.remove('pp1','pp2','pp3','pp4','pp5','pp6','pp7','pp8','popupen');
    });
});


if (window.matchMedia('(max-width: 768px)').matches) {
    let commn_space_sec1a = document.querySelector(".sec1a");

    commn_space_sec1a.scrollLeft = 200;

}


let commn_space_tevent = 'ontouchstart' in window ? 'touchstart' : 'click';
let commn_space_spppfig = document.querySelectorAll(".spppfig .scroll-hint-icon-wrap");

commn_space_spppfig.forEach((commn_space_spppfig1) => {

    commn_space_spppfig1.addEventListener(commn_space_tevent, () => {

        commn_space_spppfig1.classList.remove('is-active');

    });
});

